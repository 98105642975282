
import { service } from 'api';
import {
  UpdateServiceGatewayParams,
  AddServiceGatewayParams
} from 'types';

export const getServiceGateway = ({queryKey}: {queryKey: [string, {serviceGatewayId: number}]}) => {

  const [_key, serviceGatewayId] = queryKey;

  return service(`/api/v1/service-gateways/admin-get?id=${serviceGatewayId}`, {method: 'GET'});

}

export const getServiceGateways = () => service(`/api/v1/service-gateways/admin-all`, {method: 'GET'});

export const updateServiceGateway = (params: UpdateServiceGatewayParams) => service(`/api/v1/service-gateways/admin-edit`, {method: 'PUT', data: params})

export const disableServiceGateway = (id: number) => service(`/api/v1/service-gateways/admin-disable`, {method: 'POST', params: {id}});

export const reEnableServiceGateway = (id: number) => service(`/api/v1/service-gateways/admin-re-enable`, {method: 'POST', params: {id}});

export const addNewServiceGateway = (params: AddServiceGatewayParams) => service('/api/v1/service-gateways/admin-add', {method: 'POST', data: params});