import { useState, useMemo } from 'react';
import { Layout, Tabs, Typography, Spin } from 'antd';
import useFarmsPage from './useFarms';
import { Farm } from 'types';
import AllFarms from './components/AllFarms';

const Farms = () => {

  const [searchText, setSearchText] = useState('');
  const {
    farms,
    isFetchingFarms,
    refetchFarms,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewFarm,
    hasAddedNewFarm,
    addNewFarm,
  } = useFarmsPage();

  const onFinishUpdateOrAdd = (action: string, {
    address1,
    address2,
    city,
    district,
    zipCode,
    companyName,
    companyRegistration,
    email,
    fax,
    firstName,
    lastName,
    mobilePhone,
    phone,
    countryId,
    farmCode,
    farmName,
    id
  }: any) => {
    
    const formatedData = {
      addressData: {
        address1,
        address2,
        city,
        district,
        zipCode
      },
      contactData: {
        companyName,
        companyRegistration,
        email,
        fax,
        firstName,
        lastName,
        mobilePhone,
        phone
      },
      countryId,
      farmCode,
      farmName,
      id
    }

    addNewFarm(formatedData);

  }

  return (
    <Layout>  
      <Typography.Title>
        Farms
      </Typography.Title>
        <AllFarms
          farms={farms}
          isFetchingFarms={isFetchingFarms}
          refetchFarms={refetchFarms}
          searchText={searchText}
          setSearchText={setSearchText}
          onFinishUpdateOrAdd={onFinishUpdateOrAdd}
          isFetchingCountries={isFetchingCountries}
          hasFetchedCountries={hasFetchedCountries}
          countries={countries}
          isAddingNewFarm={isAddingNewFarm}
          hasAddedNewFarm={hasAddedNewFarm}
        />
    </Layout>
  );
}

export default Farms;