// CustomerMap.js
import { useEffect, useState, SetStateAction, Dispatch, useMemo, useCallback } from 'react';
import { Card, Row, Spin, Switch, Progress } from 'antd';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { IoIosPin } from "react-icons/io";
import L from 'leaflet';
import locationIconBlack from 'assets/images/location-black-24.png';
import locationIconGrey from 'assets/images/location-grey-24.png';

// import {
//   useMap,
//   APIProvider,
//   Map,
//   AdvancedMarker,
//   MapCameraChangedEvent,
//   Pin
// } from '@vis.gl/react-google-maps';
// import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import he from 'he';
// import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';
import _ from 'lodash';import  { filter, pickBy, values, join } from 'lodash';
import { geocodeAddress } from 'utils';
import moment from 'moment';
import * as LZString from 'lz-string';
import 'leaflet/dist/leaflet.css';
// import {
//   setKey,
//   // setDefaults,
//   // setLanguage,
//   // setRegion,
//   // fromAddress,
//   // fromLatLng,
//   // fromPlaceId,
//   // setLocationType,
//   geocode,
//   RequestType,
// } from "react-geocode";

type Poi = { key: string, location: google.maps.LatLngLiteral }

type FarmMapParams = {
  isFetchingAllCompanies: boolean,
  hasFetchedAllCompanies: boolean,
  allCompanies: [],
  fetchAllCompanies: () => void,
}

type Company = {
  id: number,
  addresses: any[],
  name: string,
  active: boolean,
  journeyStep: string,
  longitude: number,
  latitude: number,
  orgNr: string,
  notes: string,
}


const renderIcon = (state: 'regular' | 'active') => {

  return new L.Icon({
    iconUrl: state == 'active' ? locationIconBlack : locationIconGrey,//'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: state == 'active' ? [36, 36] : [24, 24],
    // iconAnchor: state == 'active' ? [12, 24] : [12, 36],
    
  });

}


const UpdateMap = () => {
  const map = useMap();
  useEffect(() => {
    map.invalidateSize();
  }, [map]);
  return null;
};

export default function FarmMap ({
  isFetchingAllCompanies,
  hasFetchedAllCompanies,
  allCompanies,
  fetchAllCompanies,
}: FarmMapParams) {

    const [companies, setCompanies]: [any[] | [], Dispatch<SetStateAction<any[]>>] = useState([]);
    const [selectedCompany, setSelectedCompany]: [any, Dispatch<SetStateAction<any>>] = useState(null);
    const [upsalesFetchPercent, setUpsalesFetchPercent]: [number, Dispatch<SetStateAction<number>>] = useState(0);
    const [companiesFetchIsComplete, setCompaniesFetchIsComplete] = useState(false);
    const [companiesHaveAllInfo, setCompaniesHaveAllInfo] = useState(false);
    const [showOnlyCustomers, setShowOnlyCustomers] = useState(false);
    const cookieName = 'hencol-admin-companies';
    const mapsAPIKey = 'AIzaSyDJoe4MugAHLGivs4ITG11JQV1y8INLJCE';
    const geocodeAPIKey = 'AIzaSyDJoe4MugAHLGivs4ITG11JQV1y8INLJCE';

    useEffect(() => {  

      if (!cookieIsComplete()) {

        fetchAllCompanies();

      } else {

        fetchAllFromStorage();

      }
      
    }, []);

    useEffect(() => {

      if (showOnlyCustomers) {

        const onlyCustomers = filter(companies, (company) => company.journeyStep == 'customer');
        setCompanies(onlyCustomers);

      } else {

        // fetchAllFromStorage();

      }
      
    }, [showOnlyCustomers])

    useEffect(() => {

      if (!isFetchingAllCompanies && hasFetchedAllCompanies) {

        console.log('has fetched and will fetch again');
        // @ts-ignore
        addToStorage({
          //@ts-ignore
          upsalesCompanies: allCompanies?.data?.data,
          //@ts-ignore
          totalAmountOfUpsalesCompanies: allCompanies?.data.metadata?.total,
        });
        // @ts-ignore
        const totalItemsToFetch = allCompanies?.data.metadata?.total;
        // @ts-ignore
        const totalItemsFetched = allCompanies?.data.metadata?.offset + allCompanies?.data.metadata?.limit;
        // structureData(allCompanies?.data?.data);

        setUpsalesFetchPercent(Math.round((totalItemsFetched/totalItemsToFetch)*100));

        // if (totalItemsFetched < 2000) {
        if (totalItemsToFetch > totalItemsFetched && !companiesFetchIsComplete) {
          // @ts-ignore
          fetchAllCompanies(totalItemsFetched);

        } else {

          setCompaniesFetchIsComplete(true);

          const currentStorage = localStorage.getItem(cookieName);
          let parsedStorage = currentStorage ? JSON.parse(LZString.decompress(currentStorage)) : [];
        
          structureData(parsedStorage);


        }

      }

    }, [isFetchingAllCompanies, hasFetchedAllCompanies]);

    const fetchAllFromStorage = () => {

      const storedCookie = localStorage.getItem(cookieName);
      // @ts-ignore
      const parsedCookie = storedCookie ? JSON.parse(LZString.decompress(storedCookie)) : [];

      setCompaniesFetchIsComplete(true);
      setCompanies(parsedCookie?.companiesWithAddresses || []);

    }

    const cleanedUpData = (data: any) => {

      return data.map(({id, addresses, name, active, journeyStep, longitude, latitude, orgNr, notes}) => ({id, addresses, name, active, journeyStep, longitude, latitude, orgNr, notes}))

    }

    const removeDuplicates = (array, key) => {
      
      const seen = new Set();
      
      return array.filter(item => {
        
        if (item == undefined) {

          console.log('WERID: ', item);

        }

        const keyValue = item[key];
      
        if (seen.has(keyValue)) {
      
          return false;
      
        } else {
      
          seen.add(keyValue);
          return true;
      
        }
      
      });
    
    };

    const addToStorage = ({upsalesCompanies, totalAmountOfUpsalesCompanies}: {upsalesCompanies: object[], totalAmountOfUpsalesCompanies: number}) => {

      console.log('adding to storage');
      const currentStorage = localStorage.getItem(cookieName);
      let parsedStorage = currentStorage ? JSON.parse(LZString.decompress(currentStorage)) : [];
      let updatedUpsaleCompanies;

      if (currentStorage) {

        // @ts-ignore
        updatedUpsaleCompanies = parsedStorage?.upsalesCompanies.concat(upsalesCompanies);
        
      } else {

        // @ts-ignore
        updatedUpsaleCompanies = upsalesCompanies;

      }

      // Ta bort dubbletter baserat på företags-ID (eller annan unik identifierare)
      const uniqueStorage = removeDuplicates(updatedUpsaleCompanies, 'id');

      console.log(uniqueStorage);

      localStorage.setItem(cookieName, LZString.compress(JSON.stringify({
        expires: parsedStorage?.expires,
        upsalesCompanies: cleanedUpData(uniqueStorage),
        // data: cleanedUpData(uniqueStorage),
        isComplete: false,
      })));

    }

    const isCoordinates = (str) => {
      // // Regex för att matcha koordinater i decimalformat
      // const coordinateRegex = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|(\d{1,2}(\.\d+)?))$/;
    
      // return coordinateRegex.test(str);

      // const coordinateRegex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      // return coordinateRegex.test(str);
      const validCharactersRegex = /^[0-9\s.,]+$/;
      return validCharactersRegex.test(str);

    };
  
    const createAddressString = (obj: any) => {

      delete obj.type;

      console.log('ADDRESS STRING: ', obj);

      // Filtrera ut alla null och tomma strängvärden
      const filteredValues = pickBy(obj, value => value !== null && value !== '');
  
      // Extrahera värdena och sammanfoga dem till en sträng
      return values(filteredValues).join(',');      

    };

    const cookieIsComplete = () => {

      const storedCookie = localStorage.getItem(cookieName);

      if (!storedCookie) {

        return false;

      }

      if (storedCookie) {

        const {expires, isComplete} = JSON.parse(LZString.decompress(storedCookie));

        if (moment(expires).unix() < moment().unix() || !isComplete) {

          return false;

        } else {

          return true;

        }

      }

    }
    

    const structureData = async ({expires, upsalesCompanies, isComplete}) => {

      const companiesWithAddresses = filter(upsalesCompanies, (item) => item && item?.addresses?.length && item?.addresses[0].type === 'Visit');

      console.log('companiesWithAddresses: ', companiesWithAddresses);

      let geocodedCompanies = [];
      const rateLimit = 2500;

      const processBatch = async (batch) => {

        const geocodeResults = await Promise.all(batch.map(async (company) => {

          const {address, city, country, state, zipCode} = company.addresses[0];

          let addressString = `${address} ${city} ${zipCode} ${country}`;

          if (address && address.length) {

            if (isCoordinates(address)) {
              
              console.log('IS COORDINATES: ', address)
              const parts = address.split(', ');
          
              if (`${parseFloat(parts[0])}`.length == 7 && `${parseFloat(parts[1])}`.length == 7) {
          
                return {
                  ...company,
                  longitude: parseFloat(parts[1]),
                  latitude: parseFloat(parts[0])
                };

              } else {

                return null;
              
              }
           
            }
            // } else {

            //   const geocodeResults = await geocodeAddress(he.decode(addressString));

            //   if (geocodeResults?.longitude && geocodeResults?.latitude) {
                
            //     return {
            //       ...company,
            //       latitude: geocodeResults.latitude,
            //       longitude: geocodeResults.longitude,
            //     };

            //   } else {

            //     return null

            //   }


            // }
          
          }

        }));

        geocodedCompanies = geocodedCompanies.concat(geocodeResults.filter((item) => item !== null));

      }

      for (let i = 0; i < companiesWithAddresses.length; i += rateLimit) {

        const batch = companiesWithAddresses.slice(i, i + rateLimit);
        console.log('index: ', i, 'total: ', companiesWithAddresses.length);
        await processBatch(batch);

        if (i + rateLimit < companiesWithAddresses.length) {
        
          await new Promise(resolve => setTimeout(resolve, 60000)); // Vänta en minut innan nästa batch

        }

      }

      // const rateLimit = 2500;

      // let geocodedCompanies = [];

      // const processBatch = async (batch) => {
        
      //   const results = await Promise.all(batch.map(async (company) => {
        
      //     let addressString = company.addresses[0]?.address;
          
      //     if (addressString && addressString.length) {

      //       if (isCoordinates(addressString.address)) {
          
      //         const parts = addressString.address.split(', ');
          
      //         if (`${parseFloat(parts[0])}`.length == 7 && `${parseFloat(parts[1])}`.length == 7) {
          
      //           return {
      //             ...company,
      //             longitude: parseFloat(parts[1]),
      //             latitude: parseFloat(parts[0])
      //           };

      //         } else {

      //           return null;
              
      //         }

      //       } else {

      //         try {

      //           const geocodeResults = await geocodeAddress(he.decode(addressString));

      //           if (geocodeResults?.longitude && geocodeResults?.latitude) {
                  
      //             return {
      //               ...company,
      //               latitude: geocodeResults.latitude,
      //               longitude: geocodeResults.longitude,
      //             };

      //           } else {

      //             return null

      //           }

      //         } catch (error) {

      //           console.error('Geocoding error:', error);
      //           return null;

      //         }

      //       }
          
      //     }

      //   }));

      //   geocodedCompanies = geocodedCompanies.concat(results.filter((item) => item !== null));

      // }

      // for (let i = 0; i < companiesWithAddresses.length; i += rateLimit) {

      //   const batch = companiesWithAddresses.slice(i, i + rateLimit);
      //   await processBatch(batch);

      //   if (i + rateLimit < companiesWithAddresses.length) {
        
      //     await new Promise(resolve => setTimeout(resolve, 60000)); // Vänta en minut innan nästa batch

      //   }

      // }

      const filteredCompanies = filter(geocodedCompanies, (company) => company);

      console.log('SETTING COMPANIES: ', filteredCompanies);

      if (!cookieIsComplete()) {

        const cookie = {
          expires: moment().add(1, 'month'),
          upsalesCompanies: removeDuplicates(filteredCompanies, 'id'),
          companiesWithAddresses: filteredCompanies,
          isComplete: true,
        }

        localStorage.setItem(cookieName, LZString.compress(JSON.stringify(cookie)));
        
      }

      setCompanies(filteredCompanies);
      
    }

    const center = {
        lat: 59.3293,
        lng: 18.0686
    };

  //   useEffect(() => {
      
  //     console.log('IS RUNNING?', companies);

  //     setTimeout(() => {

  //       if (document.getElementById('map')) {
          
  //         if (companies.length > 0 && window.google) {

  //           const map = new window.google.maps.Map(document.getElementById('map'), {
  //             center: center,
  //             zoom: 6,
  //           });

  //           console.log('should load');

  //           companies.forEach(company => {
            
  //             const marker = new window.google.maps.Marker({
                  
  //               map: map,
  //               position: { lat: company.latitude, lng: company.longitude },
  //               // @ts-ignore
  //               icon: {
  //                 // url: 'https://admin.hencol.com/static/media/logo.f21fbc4c.png',//'/src/assets/images/customer-location.png', // Ange sökväg till din anpassade ikon
  //                 // size: new window.google.maps.Size(32, 32), // Storleken på ikonen (rekommenderat minst 32x32)
  //                 // scaledSize: new window.google.maps.Size(32, 32), // Skalad storlek på ikonen
  //                 // origin: new window.google.maps.Point(0, 0), // Ursprungspunkten för ikonen (övre vänstra hörnet)
  //                 // anchor: new window.google.maps.Point(16, 32), // Fästpunkt för ikonen (mitten botten)
  //                 fillColor: "green",
  //               },

  //             });

  //             marker.addListener('click', () => setSelectedCompany(company));

  //           });
  //         }

  //       }
        
  //     }, 3000);

  // }, [companies]);

  const renderCustomer = (company: any) => {

    const {
      address,
      city,
      country,
      latitude,
      longitude,
      state,
      type,
      zipcode,
    } = company.addresses[0]

    return (
      <Card 
        title={company.name} 
        // extra={<a href="#">More</a>}
        style={{
          marginBottom: 20
        }}
      >
        <p>Org nr: {company.orgNr}</p>
        <p>Notes: {company.notes}</p>
        <p>{address} {zipcode} {city} {country} {state}</p>
        <p><a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${company.latitude}, ${company.longitude}`)}`}>Google Maps</a></p>
        <p><a target="_blank" href={`https://power.upsales.com/#/14479/accounts/${company.id}/`}>Upsales</a></p>
      </Card>
    )

  }

  const renderFilters = () => {

    return (
      <Row
        style={{
          marginBottom: 20,
        }}
      >
        <div>
          <strong
            style={{
              display: 'block',
              marginBottom: 5,
            }}
          >Only customers</strong>
          <Switch 
            checked={showOnlyCustomers}
            onChange={(value) => {

              // localStorage.setItem('LSP-admin-active-scales-only', JSON.stringify(value));
              setShowOnlyCustomers(value);
            
            }}
          />
        </div>
      </Row>
    )

  }
  
  if (!companies.length && !companiesFetchIsComplete) {

    return (
      <Row
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Fetching Upsales customers
        <Progress 
          percent={upsalesFetchPercent} 
          style={{
            marginBottom: 20,
          }}
        />
        <Spin spinning={true} />
      </Row>
    );

  }

  // const PoiMarkers = () => {
  //   return (
  //     <>
  //       {companies.map((poi: any) => {

  //         if (poi && poi.latitude && poi.longitude) {

  //           return (
  //             <AdvancedMarker
  //               key={poi.id}
  //               position={{
  //                 lat: poi.latitude,
  //                 lng: poi.longitude,
  //               }}
  //               onClick={() => setSelectedCompany(poi)}
  //             >
  //               {poi.journeyStep == 'lead' && <Pin />}
  //               {poi.journeyStep == 'disqualified' && <Pin background={'#333'} glyphColor={'#333'} borderColor={'#333'}/>}
  //               {poi.journeyStep == 'customer' && <Pin background={'green'}/>}
  //               {/* <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} /> */}
  //             </AdvancedMarker>
  //           )

  //         }

  //       })}
  //     </>
  //   );
  // };

  console.log(companies);

  return (
    <>
      {/* {renderFilters()} */}
      {selectedCompany ? renderCustomer(selectedCompany) : null}
      <MapContainer center={[59.3293, 18.0686]} zoom={5} style={{ height: '600px', width: '100%' }}>
        <UpdateMap />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* <MarkerClusterGroup> */}
          {companies.map(company => (
            <Marker 
              key={company.id}
              position={[company.latitude, company.longitude]} 
              icon={company.id == selectedCompany?.id ? renderIcon('active') : renderIcon('regular')}
              eventHandlers={{
                click: () => {
                  setSelectedCompany(company);
                },
              }}
            >
            </Marker>
          ))}
        {/* </MarkerClusterGroup> */}
      </MapContainer>
      {/* <APIProvider 
        apiKey={mapsAPIKey} 
        onLoad={() => console.log('Maps API has loaded.')}
      >
        <Map
          mapId={'b937b25da183c822'}
          defaultZoom={6}
          defaultCenter={center}
          style={{width: '100vw', height: '80vh'}}
          gestureHandling={'greedy'}
        >
          {/* <PoiMarkers /> 
          {companies.map(company => (
            <AdvancedMarker
              key={company.id}
              // tree={tree}
              onClick={() => setSelectedCompany(company)}
              // ref={setMarkerRef}
            />
          ))}
        </Map>
      </APIProvider> */}
      {/* <LoadScript googleMapsApiKey={mapsAPIKey}>
        <div id="map" style={mapContainerStyle}></div>
          {selectedCompany && (
            <InfoWindow
                position={{ lat: selectedCompany.latitude, lng: selectedCompany.longitude }}
                onCloseClick={() => setSelectedCompany(null)}
            >
              <div>
                <h2>{selectedCompany.name}</h2>
                <p>{createAddressString(selectedCustomer.addresses[0])}</p>
              </div>
            </InfoWindow>
          )}
      </LoadScript> */}
    </>
  );

};
