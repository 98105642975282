import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { AddServiceGatewayParams, UpdateServiceGatewayParams, ConnectServiceGatewayWithFarmParams } from 'types';
import { addNewServiceGateway } from 'api/serviceGateways';
import { addServiceGatewayToFarm, removeServiceGatewayFromFarm } from 'api/farms';

const { 
  getServiceGateway,
  getServiceGateways,
  updateServiceGateway,
  disableServiceGateway,
  reEnableServiceGateway,
} = api;


export function useGetServiceGateway(serviceGatewayId: number) {
  return useQuery(
    ['get-ServiceGateway', serviceGatewayId], 
    getServiceGateway,
    {
      enabled: !!serviceGatewayId,
    }
  );

}

export const useGetServiceGateways = () => useQuery('get-sgs', getServiceGateways);

export const useUpdateServiceGateway = () => useMutation((params: UpdateServiceGatewayParams) => updateServiceGateway(params));

export const useDisableServiceGateway = () => useMutation((id: number) => disableServiceGateway(id));

export const useReEnableServiceGateway = () => useMutation((id: number) => reEnableServiceGateway(id));

export const useAddNewServiceGateway = () => useMutation((params: AddServiceGatewayParams) => addNewServiceGateway(params));

export const useAddServiceGatewayToFarm = () => useMutation((params: ConnectServiceGatewayWithFarmParams) => addServiceGatewayToFarm(params));

export const useRemoveServiceGatewayFromFarm = () => useMutation((params: ConnectServiceGatewayWithFarmParams) => removeServiceGatewayFromFarm(params));