
import { getProductionOrientations, getSpecies } from 'api/other';
import { useQuery } from 'react-query';

export function useGetProductionOrientations() {

  return useQuery('get-production-orientations', getProductionOrientations);

}

export function useGetSpecies() {

  return useQuery('get-species', getSpecies);

}